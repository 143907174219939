import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'app/service/dialog.service';
import { DialogConfirmWebRefundTicketComponent } from '../dialog-confirm-web-refund-ticket/dialog-confirm-web-refund-ticket.component';

@Component({
  selector: 'dialog-web-refund-ticket',
  templateUrl: './dialog-web-refund-ticket.component.html',
  styleUrls: ['./dialog-web-refund-ticket.component.scss']
})
export class DialogWebRefundTicketComponent implements OnInit {
  currentLang: string;
  listOrderId: string;
  salesAmount: number;
  refundAmount: number;
  refundFee: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService, private dialogService: DialogService) {
    this.currentLang = this.translateService.currentLang;
    this.listOrderId = data.listOrderId;
    this.salesAmount = data.salesAmount;
  }

  ngOnInit(): void {}

  /**
   * refund
   */
  confirm() {
    const refundAmount = this.refundFee ? this.salesAmount - this.refundFee : this.salesAmount;
    this.dialogService.showDialog(
      DialogConfirmWebRefundTicketComponent,
      {
        data: {
          listOrderId: this.listOrderId,
          salesAmount: this.salesAmount,
          refundFee: this.refundFee,
          refundAmount: refundAmount
        }
      },
      result => {
        // nothing
      }
    );
  }
}
